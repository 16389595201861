import React, { useEffect } from "react";
import { FaRegClock, FaPhoneAlt, FaMapMarkedAlt } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";

function Contact() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section name="contact" className="w-full bg-[#f5fbf9] flex pb-10">
      <main className="contactContainer xl:w-[90%] rounded-3xl flex flex-col-reverse xl:flex-row p-2 md:p-0 mx-auto max-w-[1720px]">
        <div 
          data-aos="zoom-in-up" 
          data-aos-delay="100" 
          className="mapContainer xl:w-[50%] xl:mt-auto py-1 xl:h-[80%] h-[350px] w-[350px] mt-8 xl:border-none mx-auto"
        >
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.686208591449!2d19.533226199999998!3d51.7570613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471bccade3ada591%3A0xd48ddc67a13294b2!2zSsOzemVmYSBFbHNuZXJhIDE5LCA5MC0wMDEgxYHDs2TFug!5e0!3m2!1spl!2spl!4v1672430382714!5m2!1spl!2spl" 
            width="100%" 
            height="100%" 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade" 
            title="Nasza lokalizacja" 
            className="map-frame"
          ></iframe>
        </div>
        <div 
          data-aos="zoom-in-up" 
          data-aos-delay="100" 
          className="contactDetails md:w-[50%] text-[#f5fbf9] mt-[15%] md:mt-[10%] md:ml-[10%] mx-auto"
        >
          <h2 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
            Skontaktuj się z nami
          </h2>
          <h1 className="font-bold md:text-7xl text-5xl py-3 p-2 text-black">
            Jesteśmy tutaj, aby Ci pomóc!
          </h1>
          <div className="contactInfo md:text-xl flex justify-around md:py-1 xl:w-[80%] flex-col">
            <div className="info-element flex p-1 text-md text-black maven-font py-4">
              <div 
                className="info-element-icon p-1 text-[#05B2DC]" 
                title="Godziny otwarcia"
              >
                <FaRegClock />
              </div>
              <div className="info-element-type px-2">
                <time dateTime="08:00">Poniedziałek - Piątek</time>
              </div>
              <div className="info-element-details opacity-60">
                <time dateTime="18:00">08:00 - 18:00</time>
              </div>
            </div>
            <div className="info-element flex p-1 text-md text-black maven-font py-4">
              <div 
                className="info-element-icon p-1 text-[#05B2DC]" 
                title="Telefon kontaktowy"
              >
                <FaPhoneAlt />
              </div>
              <div className="info-element-type px-2">
                <a href="tel:+48606243641" className="text-black">
                  606 243 641
                </a>
              </div>
              <div className="info-element-details opacity-60">
                <a href="mailto:a.wlodarczyk78@gmail.com" className="text-black">
                  a.wlodarczyk78@gmail.com
                </a>
              </div>
            </div>
            <div className="info-element flex py-1 text-md text-black maven-font">
              <div 
                className="info-element-icon p-1 text-[#05B2DC]" 
                title="Lokalizacja"
              >
                <FaMapMarkedAlt />
              </div>
              <address className="info-element-type px-2 not-italic">
                ul. Elsnera 19, gabinet 15
              </address>
              <div className="info-element-details opacity-60">
                Łódź, Polska (od rejestracji w prawo i korytarzem w lewo, do końca)
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

export default Contact;
